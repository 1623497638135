<template>
	<ConfirmDialog></ConfirmDialog>
	
	<div class="p-grid" style="height: 100%">
		<div class="p-col-12 p-md-12" style="height: 100%">
			<div class="card p-fluid" style="height: 100%">
                <CrmDataTable baseEntityName="appointment" :options="CrmDataTable_options" :isHeaderVisible="true" @onRowSelect="onRowSelect" />
            </div>
        </div>
    </div>
</template>

<script>
import user from '../store/user';

export default {
	data() {
		return {
			CrmDataTable_options: {
				searchAttributes: ["subject", "regardingobjectidname"],
				forceSavedQueryId: '5B5F75F6-B585-4212-952A-B4CBB64E2627'
            },
		}
	},
	created() {
		if (this.profileData) {
			const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Randevular');
			if (yetkisiVarmi == false) {
				this.$router.replace({ name: 'accessdenied' });
			}
		}
	},
	methods: {
		onRowSelect(data) {
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'appointment', params: { id: data.entityId } });
			window.open(routeData.href, '_blank', features);
		}
	},
	computed: {
		profileData(){
			return this.$store.getters.getProfile;
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
